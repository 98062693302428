/*
DARK_BLUE: #6d9fda;
LIGHT_BLUE: #ddeff9;
MED_GREY: #f3eace;
COBALT: #3167B1;
WHITE: #FFFFFF;
GOLD: #FCC085;
GREEN: #507f52;
*/

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
html,
body,
input,
textarea,
button {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  color: #5c7087;
  margin: 0;
}
input,
button,
textarea {
  border-radius: 0%;
  outline: none;
  border: none;
  border-bottom: 3px solid #ccc;
  background: #fff;
  padding: 5px 10px;
}
button {
  border: none;
  background: #27589a;
  color: #ffffff;
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
button:hover {
  opacity: 0.8;
}
img {
  width: 100%;
}
svg {
  max-height: 100%;
}
hr {
  border: none;
  border-bottom: 1px solid #ccc;
}
a {
  color: #3167b1;
  transition: opacity 0.25s ease;
}
a:hover {
  opacity: 0.8;
}
.serif {
  font-family: "Cormorant Infant", serif;
  font-weight: 300;
}
.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-flower {
  position: fixed;
  top: 0px;
  right: 0px;
  transform: translate(150px, -150px);
  transition: transform 1000ms;
}
.bottom-flower {
  position: fixed;
  bottom: 0px;
  left: 0px;
  transform: translate(-150px, 150px);
  transition: transform 1000ms;
}
@media only screen and (min-width: 850px) {
  .top-flower {
    transform: translate(60, -60);
  }
  .bottom-flower {
    transform: translate(-60, 60);
  }
}
@media only screen and (min-width: 1130px) {
  .top-flower {
    transform: translate(0, 0);
  }
  .bottom-flower {
    transform: translate(0, 0);
  }
}

.top-flower-enter {
  transform: translate(200px, -200px);
}
.top-flower-enter-done {
  transform: translate(200px, -200px);
}
.bottom-flower-enter {
  transform: translate(-200px, 200px);
}
.bottom-flower-enter-done {
  transform: translate(-200px, 200px);
}

.Splash {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.Splash-Text {
  padding: 20px;
  text-align: center;
  color: #6d9fda;
}
.Splash-Photo-Wrapper {
  padding: 20px;
}
.Splash-Photo-Border {
  background: #ddeff9;
  position: relative;
  width: 202px;
  padding: 0 0 0 10px;
}
.Bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.Bio-Col-Inner {
  max-width: 600px;
}
.Bio-Col-Inner h2 {
  text-align: center;
  padding-bottom: 2rem;
}
.Bio-Col-Inner p {
  padding-bottom: 20px;
}
.Bio-Col-Inner p a {
  color: #3167b1;
}
.Bio-Pictures {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #3167b1;
  text-align: center;
  color: #fcc085;
  flex-grow: grow;
}
.Bio-Text {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f3eace;
  justify-content: center;
}
.Program {
  background-color: #285da5;
  padding: 40px;
  color: #fcc085;
  text-align: center;
}
.Program-Inner {
  border: 10px solid #27589a;
  padding: 40px;
}
.Program-Inner > h2 {
  text-align: center;
  padding-bottom: 2rem;
}
.Program-Inner > hr {
  border-bottom: 2px solid #27589a;
  margin-bottom: 20px;
}
.Program-Section {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.Program-Section h3,
.Program-Section p {
  padding: 0 20px 20px 20px;
}
.Program-Section h3 {
  width: 50%;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.Program-Section p {
  text-align: left;
  width: 50%;
  font-family: "Cormorant Infant", serif;
  font-weight: 300;
  font-size: 28px;
}

.Messages {
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  padding: 40px 0 0 0;
  flex-wrap: wrap;
}
.Messages-Title {
  width: 100%;
  text-align: center;
  color: #fcc085;
  padding-bottom: 40px;
}
.Messages-Entries {
  width: 100%;
  padding: 0 40px;
  color: #fcc085;
}
.Messages-Entries-Entry {
  padding-bottom: 40px;
}
.Messages-Entries-Entry h3,
.Messages-Entries-Entry p,
.Messages-Entries-Entry hr {
  padding-bottom: 1rem;
}
.Messages-Entries-Entry hr {
  border-bottom: 2px solid #27589a;
}
.Messages-Form {
  padding: 40px;
  background-color: #f3eace;
  width: 100%;
}
.Messages-Form h2 {
  text-align: center;
  padding-bottom: 30px;
}
.Messages-Form-Inner {
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 10px solid #e8dbb0;
}
.Messages-Form .InputGroup {
  padding-bottom: 20px;
}
.Messages-Form label {
  display: block;
  width: 100%;
}
.Messages-Form input,
.Messages-Form textarea {
  display: block;
  width: 100%;
  border-bottom-color: #e8dbb0;
}
.Messages-Form textarea {
  font-size: 15px;
  line-height: 1.6;
  max-width: 100%;
  min-width: 100%;
}
.Messages-Form .Submit-Success {
  text-align: center;
}
.form-error {
  padding-left: 25%;
  padding-bottom: 20px;
  color: #dc2121;
}
.InLieu {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 0 20px;
  text-align: center;
}

@media only screen and (min-width: 712px) {
  .Splash {
    flex-direction: row;
  }
  .Splash-Photo-wrapper {
    padding: 100px 0 0 100px;
  }
}
@media only screen and (min-width: 1024px) {
  .Bio,
  .Messages {
    flex-direction: row;
  }
  .Bio-Pictures {
    width: 50%;
    min-height: 75vh;
    height: 100%;
  }
  .Bio-Text {
    min-height: 100vh;
    width: 50%;
  }
  .Messages-Form,
  .Messages-Entries {
    width: 50%;
  }
}

.OpenSlideshowButton {
  cursor: pointer;
  max-width: 300px;
  margin: 0;
  background: none;
  transition: opacity 0.2s ease;
}
.OpenSlideshowButton:hover {
  opacity: 0.8;
}
.OpenSlideshowButton img {
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 5px 0px #000000;
  box-shadow: 1px 1px 5px 0px #000000;
}

body.slideshow-open {
  overflow-y: hidden;
}

.SlideshowWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
}
.SlideshowCloseBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: none;
  color: #ffffff;
  transition: opacity 0.2s ease;
  opacity: 0.5;
}
.SlideshowCloseBtn:hover {
  opacity: 1;
}
.SlideshowCloseBtn:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 20px;
  border-bottom: 3px solid #fff;
  transform: rotate(45deg);
}
.SlideshowCloseBtn:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 20px;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
}
.SlideshowInner {
  padding: 60px;
  max-height: 100vh;
  overflow: hidden;
}
.SlideshowInner img {
  height: calc(100vh - 120px);
  object-fit: contain;
}
.SlideshowNextBtn,
.SlideshowPrevBtn {
  position: absolute;
  width: 120px;
  top: 60px;
  bottom: 60px;
  background: none;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  opacity: 0.5;
  padding-left: 80px;
  transition: opacity 0.2s ease;
}
.SlideshowNextBtn:hover,
.SlideshowPrevBtn:hover {
  opacity: 1;
}
.SlideshowPrevBtn:before,
.SlideshowNextBtn:before {
  content: "";
  display: block;
  width: 20px;
  border-bottom: 3px solid #fff;
  transform: rotate(45deg);
  margin-top: -9px;
}
.SlideshowPrevBtn:after,
.SlideshowNextBtn:after {
  content: "";
  display: block;
  width: 20px;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
  margin-top: 9px;
}

.SlideshowPrevBtn {
  transform: rotate(180deg);
  left: 0;
}
.SlideshowNextBtn {
  right: 0;
}
.SlideshowCount {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  font-size: 14px;
  opacity: 0.5;
  color: #ffffff;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
